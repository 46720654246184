import pluralize from 'pluralize';

const initialState = {
  classrooms: [],
  classroom: {},
  projects: [],
  project: {},
  project_classrooms: [],
  student_projects: [],
  entityAdded: false,
  access_rejected_msg: null
};
const entities = (state = initialState, action) => {
  let name = '';
  switch (action.type) {
    case 'LOAD_ENTITIES_SUCCESS':
      name = pluralize(action.meta.previousAction.payload.request.modelName);
      return { ...state, [name.replace('-', '_')]: action.payload.data, entityAdded: false };
    case 'LOAD_ENTITY_SUCCESS':
      name = action.meta.previousAction.payload.request.modelName;
      return { ...state, [name.replace('-', '_')]: action.payload.data, entityAdded: false };
    case 'LOAD_ENTITIES_BY_FIELD_SUCCESS':
      name = pluralize(action.meta.previousAction.payload.request.modelName);
      return { ...state, [name.replace('-', '_')]: action.payload.data };
    case 'ADD_ENTITY_SUCCESS':
      name = pluralize(action.payload.config.modelName);
      return {
        ...state,
        [name.replace('-', '_')]: [...state[name.replace('-', '_')], action.payload.data],
        entityAdded: true
      };
    case 'DELETE_ENTITY_SUCCESS':
      return { ...state, deletedEntity: action.payload.data };
    default:
      return state;
  }
};

export default entities;
