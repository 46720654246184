import React, { 
    useState, 
    useEffect, 
} from 'react';
import { 
    Redirect, 
    Route,
} from 'react-router-dom';
import { ACCESS_REJECTED_MESSAGE as errMsg } from '../env';

const ProtectedRoute = ({redirectUrl, user, ...props}) => {
    console.log("protected route props", props);
    if (user === 'authed') {
        if (typeof redirectUrl === 'undefined') {
            redirectUrl = '/dashboard';
        }
        if (localStorage.getItem('user')) {
            return <Redirect to={redirectUrl} />
        }
        return <Route {...props} />
    }
    if (user === 'unauthed') {
        if (typeof redirectUrl === 'undefined') {
            redirectUrl = '/';
        }
        if (localStorage.getItem('user') != null) {
            return <Route {...props} />;
        }
        const { computedMatch: { url } } = props;
        let msg;
        switch(true) {
            case url.startsWith('/shareproject'):
                msg = errMsg['shareproject'];
                break;
        }
        if (redirectUrl === '/login') {
            return <Redirect
                to={{
                    pathname: redirectUrl,
                    state: {
                        url,
                        msg
                    }
                }} 
            />;
        }
        return <Redirect to={redirectUrl} />;
    }
};

ProtectedRoute.defaultProps = {
    user: 'unauthed',
};

export default ProtectedRoute;
