import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';
import { CloseButton, Fade } from '../components/common/Toast';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import ProtectedRoute from '../hoc/ProtectedRoute';

const Landing = loadable(() => import('../components/landing/Landing'));
const Login = loadable(() => import('../pages/login'));
const StudentLogin = loadable(() => import('../pages/login/studentLogin'));
const Register = loadable(() => import('../pages/register'));
const GoogleAuthCallback = loadable(() => import('../pages/GoogleAuthCallback'));

const Layout = () => {
  useEffect(() => {
    Landing.preload();
    Login.preload();
    Register.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <ProtectedRoute user="authed" path="/" exact component={Landing} /> 
        <ProtectedRoute user="authed" path="/login" exact component={Login} />
        <ProtectedRoute user="authed" path="/studentlogin" exact component={StudentLogin} />
        <ProtectedRoute user="authed" path="/register" exact component={Register} />
        <Route path="/auth/callback/google" exact component={GoogleAuthCallback} />
        <Route path="/errors" component={ErrorLayout} />
        <Route component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
