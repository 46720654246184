import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import axios from 'axios';

import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json'
});

function errorHandler({ getState, dispatch, error }) {
  // debugger;
  console.log(error.response);
  if (error.response.status === 401) {
    // auto logout if 401 response returned from api
    // dispatch(actions.logout());
    // window.location.reload(true);
  }
}

const middlewareConfig = {
  onError: errorHandler,
  interceptors: {
    request: [
      function({ getState, dispatch, getSourceAction }, req) {
        console.log(req); // contains information about request object
        if (localStorage.getItem('user') != null) {
          req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('user')).jwt}`;
        }
        //dispatch(showLoading());
        return req;
      }
    ],
    response: [
      function({ getState, dispatch, getSourceAction }, res) {
        console.log(res); // contains information about request object
        //dispatch(hideLoading());
        return res;
      }
    ]
  }
};

const store = createStore(
  rootReducer, // custom reducers
  applyMiddleware(
    thunkMiddleware,
    axiosMiddleware(client, middlewareConfig) // second parameter options can optionally contain onSuccess, onError, onComplete, successSuffix, errorSuffix
  )
);

ReactDOM.render(
  <Provider store={store}>
    <Main>
      <App />
    </Main>
  </Provider>,
  document.getElementById('main')
);
