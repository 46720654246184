export const API_URL = 'https://api.smartslydes.com';
export const API_ENDPOINTS = {
    auth: {
        login: '/auth/local',
        register: '/auth/local/register'
    },
    classrooms: {
        all: '/classrooms/',
        add: '/classrooms/',
        del: '/classrooms/',
        get: '/classrooms/'
    },
    students: {
        all: '/students/',
        del: '/students/',
        add: '/students/',
    }
};
export const ACCESS_REJECTED_MESSAGE = {
    shareproject: 'To use this project please login, if you don’t have an account, you can create a free account here',
}

export default {
    API_URL,
    API_ENDPOINTS,
    ACCESS_REJECTED_MESSAGE,
}